@import "~@/styles/variables/variables.scss";


























.factory-alarm {
	height: 100%;
}
